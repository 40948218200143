import { graphql, useStaticQuery } from 'gatsby'

const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery($locale: String) {
        company: contentfulNavigationBar(
          slug: { eq: "footer-company-links" }
          node_locale: { eq: $locale }
        ) {
          pages {
            ... on ContentfulPage {
              __typename
              id
              slug
              title
            }
            ... on ContentfulExternalLink {
              __typename
              id
              title
              url
            }
          }
          companyAddress {
            childMarkdownRemark {
              html
            }
          }
        }
        help: contentfulNavigationBar(
          slug: { eq: "footer-help-links" }
          node_locale: { eq: $locale }
        ) {
          pages {
            ... on ContentfulPage {
              __typename
              id
              slug
              title
            }
            ... on ContentfulExternalLink {
              __typename
              id
              title
              url
            }
          }
        }
        info: contentfulNavigationBar(
          slug: { eq: "footer-info-links" }
          node_locale: { eq: $locale }
        ) {
          pages {
            ... on ContentfulPage {
              id
              slug
              title
            }
            ... on ContentfulExternalLink {
              __typename
              id
              title
              url
            }
          }
        }
      }
    `
  )

  return data
}

export default useFooterQuery
