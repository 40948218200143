/** @jsx jsx */
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import formatMoney from '~/utils/formatMoney'
import useProductVariants from '~/hooks/components/use-product-variants'
import { useIntl } from 'gatsby-plugin-intl'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductOptionSelector from '~/components/Product/OptionSelector'
import ImgLoader from '~/components/Generic/ImgLoader'
import { Fragment, useEffect } from 'react'

const ProductPreviewCard = ({ product, isActive }) => {
  const { locale } = useIntl()
  const { currentVariant, selectVariantWithOptions } = useProductVariants(
    product.variants
  )
  const {
    sku,
    shopifyId,
    price,
    regularPrice,
    limitOnePerCustomer,
    comingSoon
  } = currentVariant

  // Choices that will be presented to the user
  const allowedOptionTypes =
    product.optionTypes?.filter(type => type.differentPerVariant) || []

  // All the available choices across the variants
  const allVariantOptions = [
    ...product.variants
      .filter(v => !v.hideEverywhere)
      .flatMap(v => v.optionValues)
  ]
    .filter(Boolean)
    .reverse()
    .filter(
      (option, index, options) =>
        options.slice(index + 1).find(o => o.slug === option.slug) === undefined
    )
    .reverse()

  // Cross referenced list of available variants
  const allowedOptionValues = allowedOptionTypes.map(optionType => {
    // All option values across variants for the current optionType
    const globallyAvailableOptionValues = allVariantOptions.reduce(
      (acc, option) => {
        return option.option_type[0].slug === optionType.slug
          ? [...acc, option]
          : acc
      },
      []
    )

    return { ...optionType, optionValues: globallyAvailableOptionValues }
  })
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    }
  }, [])

  return (
    <Flex
      sx={{
        display: ['none', null, null, isActive ? 'flex' : null],
        position: 'absolute',
        top: 0,
        left: '100%',
        bottom: 0,
        flexFlow: 'row nowrap',
        alignItems: 'center',
        width: 'var(--preview-card-width)',
        maxWidth: [null, null, null, '74.9rem', '111.3rem'],
        color: 'black',
        backgroundColor: 'white',
        padding: '1.6rem',
        boxShadow: '0 0 10px 1px rgba(0,0,0,0.1)'
      }}
    >
      <Box
        sx={{
          width: [null, null, null, '100%'],
          maxWidth: [null, null, null, '36rem', '64rem'],
          position: 'relative',
          height: '100%',
          paddingRight: [null, null, null, 2, 4]
        }}
      >
        <ImgLoader
          image={currentVariant.mainImage}
          alt={
            currentVariant.mainImage.description ??
            currentVariant.mainImage.title
          }
          bgColor="transparent"
          startPercent={0}
          forwardSx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            img: { objectFit: 'contain !important' }
          }}
        />
      </Box>

      <Box
        sx={{
          flex: [null, null, null, '1 1 0'],
          minWidth: [null, null, null, null, '390px'],
          paddingLeft: [null, null, null, 3, 4],
          borderLeft: theme => `1px solid ${theme.colors.borderInactive}`
        }}
      >
        <Heading
          as="h2"
          variant="h2"
          sx={{
            fontWeight: 'medium',
            textTransform: 'uppercase',
            fontSize: [
              null,
              '2.5rem',
              null,
              null,
              product.name.length > 11 ? '3rem' : '4rem'
            ]
          }}
        >
          {product.name}
        </Heading>

        <Text
          as="p"
          sx={{
            fontSize: [null, '1.1rem', null, null, 3],
            textTransform: 'uppercase',
            mb: [null, null, null, 2, 4]
          }}
        >
          {product.shortDescription}
        </Text>

        <Flex
          sx={{
            flexFlow: 'row nowrap',
            justifyContent: 'space-evenly',
            alignItems: 'flex-end',
            marginBottom: [null, null, null, 2, 4],
            textAlign: 'center'
          }}
        >
          {product.cardSpecs?.map(spec => (
            <Flex
              as="article"
              sx={{
                flex: '1 1 0',
                height: '100%',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                ':not(:last-of-type)': {
                  paddingX: [null, null, null, 2, 3],
                  borderRight: theme =>
                    `1px solid ${theme.colors.borderInactive}`
                }
              }}
              key={spec.id}
            >
              <Text
                as="p"
                sx={{
                  fontWeight: 'medium',
                  fontSize: [null, 3, null, null, '2.5rem'],
                  lineHeight: '1em'
                }}
              >
                {spec.value}
              </Text>

              <Heading
                as="h3"
                variant="h3"
                sx={{ fontSize: [null, '1.1rem', null, null, 3] }}
              >
                {spec.name}
              </Heading>
            </Flex>
          ))}

          <article sx={{ flex: '1 1 0', height: '100%' }}>
            {allowedOptionValues.map(option => (
              <ProductOptionSelector
                title={option.name}
                type={option.slug}
                options={option.optionValues}
                currentVariant={currentVariant}
                handleSelection={option => selectVariantWithOptions(option)}
                alignment="center"
                reversed={true}
                gap="0.2rem"
                size={['1rem', null, null, null, '2rem']}
                labelSx={{
                  variant: 'text.h3',
                  mb: 0,
                  mt: 2,
                  fontSize: [null, '1.1rem', null, null, 3],
                  gap: 0
                }}
                optionSx={{
                  border: '1px solid rgba(0,0,0,0.3)'
                }}
                key={option.slug}
                previewCard
              />
            ))}
          </article>
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center'
          }}
        >
          <Text
            as="p"
            sx={{
              variant: 'text.h2',
              fontSize: [null, '2rem', null, null, '3rem'],
              fontWeight: 'medium',
              textAling: 'center',
              marginBottom: ['8px']
            }}
          >
            {regularPrice !== price ? (
              <Fragment>
                <span sx={{ textDecoration: 'line-through', mr: '10px' }}>
                  {formatMoney(regularPrice, { locale })}
                </span>
                <span sx={{ color: 'red' }}>
                  {formatMoney(price, { locale })}
                </span>
              </Fragment>
            ) : (
              formatMoney(price, { locale })
            )}
            <span
              sx={{
                fontSize: [null, '1rem', null, null, '1.2rem'],
                fontWeight: 'normal',
                marginLeft: '0.8rem'
              }}
            >
              USD
            </span>
          </Text>
        </Flex>
        {/* Placement v2  */}
        <Flex
          sx={{
            justifyContent: 'center'
          }}
        >
          <klarna-placement
            data-key="credit-promotion-small"
            data-locale="en-US"
            data-purchase-amount={`${Math.round(price)}00`}
          ></klarna-placement>
          {/* end Placement  */}
        </Flex>
        <Flex
          dir="row"
          sx={{
            gridColumn: ['span 2', null, null, null],
            justifyContent: ['flex-start', null, null, null, 'center'],
            alignItems: 'center',
            marginTop: [null, null, null, 3, 4],
            width: '100%',
            button: {
              height: [null, null, null, '31px', '44px'],
              width: '100%',
              margin: 0
            }
          }}
        >
          <ProductAddToCartButton
            price={price}
            quantity={1}
            regularPrice={regularPrice}
            sku={sku}
            limitOnePerCustomer={limitOnePerCustomer}
            variantId={shopifyId}
            comingSoon={comingSoon}
            sx={{
              fontWeight: 'bold',
              fontSize: [null, '1.3rem', null, null, 3]
            }}
          />
        </Flex>
      </Box>
    </Flex>
  )
}

export default ProductPreviewCard
