import { graphql, useStaticQuery } from 'gatsby'

const useNavigationQuery = () => {
  const data = useStaticQuery(graphql`
    query NavigationBarQuery {
      contentfulNavigationBar(slug: { eq: "navigation-bar" }) {
        id
        slug
        name
        pages {
          ... on ContentfulCollection {
            __typename
            id
            title
            slug
            lineup {
              id
              name
              slug
            }
          }
          ... on ContentfulLineup {
            __typename
            id
            name
            slug
            noPage
            collections {
              ... on ContentfulCollection {
                __typename
                id
                slug
                title
                displayProductDropdown
                products {
                  ... on ContentfulProduct {
                    __typename
                    id
                    name
                    slug
                    shortDescription
                    cardSpecs {
                      id
                      title
                      value
                      name
                    }
                    optionTypes {
                      name
                      slug
                      differentPerVariant
                    }
                    variants {
                      ... on ContentfulVariant {
                        __typename
                        id
                        hideEverywhere
                        sku
                        shopifyId
                        price
                        regularPrice
                        limitOnePerCustomer
                        optionValues {
                          name
                          slug
                          presentation
                          optionalRendering
                          option_type {
                            slug
                            name
                          }
                        }
                        mainImage {
                          id
                          gatsbyImageData(
                            width: 1600
                            layout: CONSTRAINED
                            aspectRatio: 1.5
                          )
                          title
                          description
                        }
                        comingSoon
                      }
                    }
                  }
                }
              }
              ... on ContentfulLineup {
                __typename
                id
                name
                slug
                noPage
                collections {
                  ... on ContentfulCollection {
                    __typename
                    id
                    title
                    slug
                  }
                }
              }
            }
            featuredPages {
              ... on ContentfulPage {
                __typename
                id
                title
                slug
              }
              ... on ContentfulExternalLink {
                __typename
                id
                title
                url
              }
            }
          }
          ... on ContentfulPage {
            __typename
            id
            title
            slug
            sections {
              id
              slug
              title
            }
            relatedPages {
              slug
              title
              id
            }
          }
          ... on ContentfulExternalLink {
            __typename
            id
            name
            title
            url
            relatedPages {
              ... on ContentfulExternalLink {
                __typename
                id
                name
                title
                url
              }
              ... on ContentfulPage {
                __typename
                id
                title
                slug
              }
            }
          }
        }
        popularSearches {
          ...ProductCardFragment
        }
        trendingSearches {
          name
          mainImage {
            id
            gatsbyImageData(width: 1600, layout: CONSTRAINED)
            title
            description
          }
          product {
            ...ProductCardFragment
          }
        }
      }
    }
  `)

  return data.contentfulNavigationBar
}

export default useNavigationQuery
