/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui'
import { Link } from 'gatsby'
import useNotificationBar from '~/hooks/components/use-notification-bar'
import useVariantAvailability from '~/hooks/components/use-variant-availability'

const NotificationBar = ({ bannerHeight }) => {
  const { loaded, notification } = useNotificationBar()

  const { isLowStock, productLink } = notification

  const { quantityAvailable } = useVariantAvailability({
    variantId: notification?.productVariant?.shopifyId
  })

  if (!notification) return null

  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: 'black',
        height: [bannerHeight + 'px'],
        textAlign: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: 10,
        px: '10px'
      }}
    >
      {isLowStock && (
        <Link
          sx={{
            display: 'flex',
            flexDirection: ['column', null, null, 'row', null],
            alignItems: 'center',
            mr: ['6px', null, null, '35px']
          }}
          to={productLink}
        >
          <Text
            sx={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: ['28px'],
              lineHeight: '1',
              mr: '8px'
            }}
          >
            {quantityAvailable}
          </Text>
          <Text
            sx={{
              fontSize: ['10px', null, null, '12px'],
              lineHeight: '1.3',
              color: 'white'
            }}
          >
            REMAINING
          </Text>
        </Link>
      )}
      <Box
        sx={{
          opacity: loaded ? '1' : '0',
          fontSize: ['1.2rem', null, '1.4rem', null, '1.5rem'],
          color: 'white',
          maxWidth: isLowStock ? '67%' : 'none',
          a: {
            textDecoration: isLowStock ? 'none' : 'underline'
          }
        }}
        dangerouslySetInnerHTML={{
          __html: notification.description.childMarkdownRemark.html
        }}
      />
    </Flex>
  )
}

export default NotificationBar
