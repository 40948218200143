export const changeVariant = (locVariant, option, setVar, prod) => {
  const unchangedOptionValues = locVariant.optionValues.reduce((acc, cur) => {
    return cur.option_type[0].slug !== option.option_type[0].slug &&
      locVariant.optionValues.find(ov => ov.slug === cur.slug)
      ? [...acc, cur]
      : acc
  }, [])

  const newVariant = prod.variants.find(v => {
    if (!v.optionValues.find(ov => ov.slug === option.slug)) {
      return false
    }
    for (const unchangedOptionValue of unchangedOptionValues) {
      if (!v.optionValues.find(ov => ov.slug === unchangedOptionValue.slug)) {
        return false
      }
    }
    return true
  })
  // Hmmmm will this work
  if (newVariant) {
    setVar(newVariant)
    if (typeof window !== 'undefined') {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${generateVariantQueryString(
          newVariant,
          prod
        )}`
      )
    }
  } else {
    setVar(prod.variants.find(v => v.optionValues.includes(option)))
  }
}

export const generateVariantQueryString = (v, prod) => {
  if (v !== undefined && v.optionValues) {
    return `?${v.optionValues
      .map(
        (ov, idx, arr) =>
          `${ov.option_type[0].slug}=${ov.slug}${
            arr.length > 1 && idx < arr.length - 1 ? '&' : ''
          }`
      )
      .join('')}`
  } else if (prod.variants[0].optionValues) {
    return `?${prod.variants[0].optionValues
      .map(
        (ov, idx, arr) =>
          `${ov.option_type[0].slug}=${ov.slug}${
            arr.length > 1 && idx < arr.length - 1 ? '&' : ''
          }`
      )
      .join('')}`
  }
  return ''
}

export const getVariantFromQueryParams = (variants, qparams) => {
  // 1. Get all params, filter out UTM values, and create an array of their values

  const paramsObj = Object.fromEntries(qparams)
  const paramsObjNoUtm = Object.keys(paramsObj)
    .filter(key => !/utm/.test(key))
    .reduce((obj, key) => {
      obj[key] = paramsObj[key]
      return obj
    }, {})

  // This is a little bit of a janky test since email marketing assumes there is no query string present
  const paramValues = Object.values(paramsObjNoUtm).map(x => x.split('?')[0])

  // 2. Create an array of just variant value arrays to match the params

  let simpleVariants = variants.map(v => v.optionValues.map(ov => ov.slug))

  const indexOf = (xs, ys) =>
    ys.findIndex(yy => {
      if (xs.length != yy.length) return false
      return yy.reduce((b, y, i) => b && Object.is(y, xs[i]), true)
    })

  // 3. find and return the index
  const newV = variants[indexOf(paramValues, simpleVariants)]
  if (newV !== undefined) return newV
  return variants[0]
}

// ?utm_campaign=2022_06_09_electrify_survey&utm_source=braze&utm_medium=email&utm_content=z_miami
