/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, Flex, Button } from 'theme-ui'

const NavToggle = ({ onClick }) => {
  return (
    <Button
      variant="iconOnly"
      onClick={onClick}
      sx={{
        justifySelf: 'flex-start',
        cursor: 'pointer',
        flexShrink: 0,
        variant: 'text.navLink',
        marginRight: '1rem',
        display: [null, null, null, 'none']
      }}
      aria-label="Toggle menu"
    >
      <Flex
        sx={{
          width: '15px',
          height: '11px',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div
          sx={{ width: '15px', height: '1px', backgroundColor: 'textInverted' }}
        />
        <div
          sx={{ width: '15px', height: '1px', backgroundColor: 'textInverted' }}
        />
        <div
          sx={{ width: '15px', height: '1px', backgroundColor: 'textInverted' }}
        />
      </Flex>
    </Button>
  )
}

NavToggle.propTypes = {
  onClick: PropTypes.func
}

export default NavToggle
