import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import useMetadata from '~/hooks/components/use-metadata'

function Metadata({ description, image, lang = 'en', meta = [], title }) {
  const { pathname } = useLocation()
  /*
   *  This is the order of precedence used by `useMetadata` to determine the page's metadata:
   *
   *  1. First, we look for a pageMetadata entry in Contentful with a matching path.
   *  2. Second, we look at the props passed to this component.
   *  3. Third, we look at the default pageMetadata entry in Contentful.
   */
  const { metaTitle, metaDescription, metaImage, metaUrl } = useMetadata({
    description,
    image,
    title
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
        'data-path': pathname.replaceAll('/', '')
      }}
      title={metaTitle}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover`
        },
        {
          name: `title`,
          content: metaTitle
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: `https:${metaImage}`
        },
        {
          property: `og:url`,
          content: metaUrl
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:url`,
          content: metaUrl
        },
        {
          name: `twitter:image`,
          content: `https:${metaImage}`
        }
      ].concat(meta)}
      link={[
        {
          rel: 'icon',
          href: '/favicon.ico'
        }
      ]}
    />
  )
}

Metadata.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string
    })
  ),
  title: PropTypes.string
}

export default Metadata
