const formatMoney = (
  cents,
  { locale, currency = 'USD' } = { locale: 'en-US', currency: 'USD' }
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(cents)
}

export default formatMoney
