/** @jsx jsx */
import { Box, Text, jsx, Button } from 'theme-ui'
import { openConsentManager } from '@segment/consent-manager'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const FooterDataConsent = () => {
  const translate = useTranslate()

  return (
    <Button
      onClick={openConsentManager}
      role="button"
      sx={{
        cursor: 'pointer',
        mt: '20px',
        mb: '50px',
        color: 'textInverted',
        textTransform: 'none',
        border: 'none',
        padding: 0,
        ':hover': {
          backgroundColor: 'transparent !important'
        }
      }}
      tabIndex="0"
    >
      <Text sx={{ fontSize: '13px', color: 'white' }}>
        {translate('footer.dataconsent.title')}
      </Text>
    </Button>
  )
}

export default FooterDataConsent
