/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, Box } from 'theme-ui'
import {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  Fragment
} from 'react'

const ScrollContext = createContext()
export const useScrollContext = () => useContext(ScrollContext)

export const ScrollContent = ({
  showBanner,
  bannerHeight,
  children,
  ...props
}) => {
  const containerRef = useRef()
  const [snaps, setSnaps] = useState(true)
  const [scrollY, setScrollY] = useState()
  const [snapType, setSnapType] = useState('none')

  useEffect(() => {
    const scrollHandler = () => {
      setScrollY(containerRef.current?.scrollTop)
    }

    containerRef.current?.addEventListener('scroll', scrollHandler)

    return () => {
      containerRef.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <ScrollContext.Provider
      value={{
        rootElementRef: containerRef,
        scrollY,
        setSnapType,
        setSnaps
      }}
    >
      <Box
        sx={{
          height: [
            null,
            null,
            null,
            snaps
              ? showBanner
                ? `calc(100vh - ${bannerHeight}px)`
                : '100vh'
              : null
          ],
          overflowY: [null, null, null, snaps ? 'auto' : null],
          overflowX: 'hidden',
          scrollSnapType: [null, null, null, snapType],
          scrollSnapStop: 'always',
          marginTop: showBanner ? bannerHeight + 'px' : 0,
          transition: '0.25s linear'
        }}
        ref={containerRef}
        {...props}
      >
        {children}
      </Box>
    </ScrollContext.Provider>
  )
}

export const ScrollSnaps = ({ showBanner, children }) => {
  const context = useScrollContext()
  const [isChrome, setIsChrome] = useState(false)

  if (!context)
    throw new Error('ScrollSnaps must be used within ScrollContent.')

  const startRef = useRef()
  const endRef = useRef()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsChrome(!!window.chrome)
    }
  }, [])

  useEffect(() => {
    if (isChrome) {
      if (
        context.scrollY >= 0 &&
        context.scrollY < endRef.current?.offsetTop + 200
      ) {
        context.setSnapType('y mandatory')
      } else {
        context.setSnapType('none')
      }
    } else {
      if (
        context.scrollY >= 0 &&
        context.scrollY < endRef.current?.offsetTop - 40
      ) {
        context.setSnapType('y mandatory')
      } else {
        context.setSnapType('none')
      }
    }
  }, [context.scrollY])

  return (
    <Fragment>
      <Box
        sx={{
          height: 0,
          '~ *': { scrollSnapAlign: 'start', scrollSnapStop: 'always' }
        }}
        ref={startRef}
      />

      {children}

      <Box
        sx={{
          height: 0,
          '~ *': { scrollSnapAlign: 'center', scrollSnapStop: 'always' }
        }}
        ref={endRef}
      />
    </Fragment>
  )
}
