/** @jsx jsx */
import { Box, Link as TUILink, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import NavCollectionLink from '../NavCollectionLink'
import { useState } from 'react'

const CatalogLinkDropdown = ({
  isOpen,
  collections = [],
  pages,
  catalogSlug,
  setOpenFilter
}) => {
  const [activeCollection, setActiveCollection] = useState(null)

  const numberOfColumns = Math.ceil(collections.length / 5) + 1
  const itemsPerColumn = collections.length + pages.length < 10 ? 3 : 4

  return (
    <Box
      sx={{
        position: ['static', null, null, 'absolute'],
        top: [null, null, null, '100%'],
        width: ['auto'],
        padding: isOpen
          ? catalogSlug === 'all-bikes'
            ? '10px 0 30px'
            : '20px 0 20px'
          : 0,
        height: isOpen ? 'auto' : '0',
        overflow: 'auto',
        ':hover': {
          overflow: collections.length > 11 ? 'auto' : 'initial'
        },
        backgroundColor: [null, null, null, 'black70'],
        display: isOpen ? 'block' : 'none',
        flexDirection: ['column', null, null, 'row'],
        justifyContent: 'center',
        ':after': {
          content: catalogSlug === 'all-bikes' ? '""' : null,
          width: '100%',
          height: '200%',
          position: 'absolute',
          bottom: '-200%',
          background: 'transparent',
          display: ['none', null, null, 'block', null]
        }
      }}
    >
      {[...Array(numberOfColumns).keys()].map(i => {
        return (
          <Box
            as="ul"
            sx={{
              display: isOpen ? 'flex' : 'none',
              flexDirection: 'column',
              listStyleType: 'none',
              padding: 0,
              width: 'auto'
            }}
          >
            {collections
              .slice(i * itemsPerColumn, i * itemsPerColumn + itemsPerColumn)
              .map(collection => (
                <NavCollectionLink
                  catalogSlug={catalogSlug}
                  collection={collection}
                  activeCollection={activeCollection}
                  onActive={() => setActiveCollection(collection.id)}
                  linkSx={{
                    variant: 'text.navLink',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: ['1.7rem', null, null, 1, 4],
                    padding: [
                      '1.6rem 1.6rem 1.6rem calc(var(--link-horizontal-inset) * 1.5)',
                      null,
                      null,
                      '0.3rem 1.6rem 0.3rem calc(var(--link-horizontal-inset) * 1.5)',
                      '2rem 2.4rem 1rem var(--link-horizontal-inset)'
                    ]
                  }}
                  key={collection.id}
                />
              ))}
          </Box>
        )
      })}
      <Box
        as="ul"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          listStyleType: 'none',
          padding: 0,
          width: 'auto'
        }}
      >
        {pages.map(page => (
          <li key={page.id}>
            <TUILink
              as={
                page.__typename === 'ContentfulExternalLink' ||
                page.slug === 'dealer-locator'
                  ? TUILink
                  : Link
              }
              href={
                page.__typename === 'ContentfulExternalLink'
                  ? page.url
                  : page.slug === 'dealer-locator'
                  ? `https://super73.com/dealer-locator`
                  : `/${page.slug}`
              }
              to={
                page.__typename === 'ContentfulExternalLink'
                  ? page.url
                  : `/${page.slug}`
              }
              target={
                page.__typename === 'ContentfulExternalLink'
                  ? '_blank'
                  : '_self'
              }
              aria-label={`${page.title} Navigation Menu Link`}
              rel="noopener noreferrer"
              sx={{
                variant: 'text.navLink',
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                color: ['white', null, null, 'rgba(256,256,256,0.8)'],
                fontSize: ['1.7rem', null, null, 1, 4],
                whiteSpace: 'nowrap',
                padding: [
                  '1.6rem 1.6rem 1.6rem calc(var(--link-horizontal-inset) * 1.5)',
                  null,
                  null,
                  '0.3rem 1rem 0.3rem calc(var(--link-horizontal-inset) * 1.5)',
                  '1.6rem 2.4rem 1.6rem var(--link-horizontal-inset)'
                ],
                ':hover': {
                  fontWeight: 'medium',
                  color: 'white'
                }
              }}
            >
              {page.title}
            </TUILink>
          </li>
        ))}
      </Box>
    </Box>
  )
}

CatalogLinkDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  catalogSlug: PropTypes.string.isRequired,
  collections: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  )
}

export default CatalogLinkDropdown
