/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import LogoSimplified from '~/assets/images/logos/logo-small.svg'

const NavLogo = () => {
  return (
    <Link
      to="/"
      sx={{
        flexShrink: 0,
        height: ['3rem', null, '4.5rem', '2.8rem', '4.8rem'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          height: '100%',
          width: 'auto',
          path: { fill: 'white' }
        }
      }}
      aria-label="Home"
    >
      <LogoSimplified />
    </Link>
  )
}

export default NavLogo
