/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import {
  logSentryError,
  useAnalytics,
  useTranslate
} from '@chordcommerce/gatsby-theme-performance'
import ArrowRight from '~/assets/images/icons/arrow-right-light.svg'
import { Fragment } from 'react'

const FooterNewsletterSignup = () => {
  const translate = useTranslate()
  const { trackEmailCaptured } = useAnalytics()
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const { email } = data

    const payload = {
      email,
      placement: {
        page: 'all-pages',
        component: 'footer'
      }
    }

    try {
      trackEmailCaptured(payload)
      setSubmitted(true)
    } catch (err) {
      logSentryError(err, { source: 'FooterNewsletterSignup' })
    }
  }

  return (
    <Box>
      <Box>
        <Text
          sx={{
            variant: 'text.small',
            marginBottom: 3
          }}
        >
          {translate('footer.newsletter_signup.text')}
        </Text>

        {!submitted && (
          <Fragment>
            <Flex
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                position: 'relative',
                flexFlow: 'row nowrap'
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  flex: '1 1 0',
                  input: { paddingRight: '3.4rem', fontSize: '1.3rem' }
                }}
              >
                <FormInputWithLabel
                  name="email"
                  title={translate('footer.newsletter_signup.input_label')}
                  label={translate('footer.newsletter_signup.input_label')}
                  placeholder={translate(
                    'footer.newsletter_signup.input_label'
                  )}
                  aria-label={translate('footer.newsletter_signup.input_label')}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: translate('validation.invalid_email')
                    }
                  })}
                />
              </Box>

              <Button
                type="submit"
                variant="secondary"
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: '8px',
                  width: '3.2rem',
                  height: '3.2rem',
                  margin: 0,
                  padding: 0,
                  border: 0,
                  color: 'borderInactiveInverted',
                  opacity: 1,
                  svg: {
                    width: '100%',
                    height: '100%',
                    'path, line': { transition: '0.25s ease' }
                  },
                  ':hover': {
                    backgroundColor: 'initial',
                    opacity: 1,
                    'path, line': {
                      stroke: 'white'
                    }
                  }
                }}
                aria-label={translate('footer.newsletter_signup.button')}
              >
                <ArrowRight />
              </Button>
            </Flex>

            {errors && errors['email'] && (
              <Text
                variant="formError"
                sx={{
                  fontSize: 1
                }}
              >
                {errors['email'].type === 'required' &&
                  translate('validation.required_field')}

                {errors['email'].type === 'pattern' &&
                  translate('validation.invalid_email')}
              </Text>
            )}
          </Fragment>
        )}

        {submitted && (
          <Box
            sx={{
              margin: [null, 'auto'],
              maxWidth: [null, '295px', 'none'],
              textAlign: 'left',
              fontSize: [2, null, 3, null]
            }}
          >
            {translate('footer.newsletter_signup.confirmation')}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FooterNewsletterSignup
