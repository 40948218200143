/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState, Fragment } from 'react'
import { Button, Text, Spinner, jsx } from 'theme-ui'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'
import useVariantAvailability from '~/hooks/components/use-variant-availability'
import { navigate } from '@reach/router'
import aa from 'search-insights'

const ProductAddToCartButton = ({
  price,
  search,
  quantity,
  regularPrice,
  sku,
  handleAddToCart,
  limitOnePerCustomer,
  variantId,
  ctaText,
  externalLink,
  customCtaText,
  forwardSx,
  comingSoon,
  ...props
}) => {
  let userToken = ''
  aa('getUserToken', null, (err, algoliaUserToken) => {
    if (err) {
      console.error(err)
      return
    }

    userToken = algoliaUserToken
  })
  const translate = useTranslate()
  const { cart, addToCart } = useCart()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleSubmit = async event => {
    if (event) event.preventDefault()
    if (buttonIsLoading || isFetchingAvailability || !isAvailable || comingSoon)
      return

    let variantInCart = cart.data.lineItems.some(
      product => product.variant.sku === sku
    )

    if (limitOnePerCustomer && variantInCart) {
      setApiError('Limit of 1 item per customer')
    } else {
      setButtonIsLoading(true)
      setApiError(null)

      try {
        await addToCart(variantId, quantity)
        if (search?.objectID && search?.queryID) {
          aa('convertedObjectIDsAfterSearch', {
            index: 'products',
            eventName: 'Product added to cart',
            userToken,
            objectIDs: [search.objectID],
            queryID: search.queryID
          })
        }
        navigate('/cart')
      } catch (error) {
        setApiError(translate('error.api.default'))
      }

      setButtonIsLoading(false)
    }
  }

  const {
    isFetchingAvailability,
    isAvailable,
    error: availabilityError
  } = useVariantAvailability({
    variantId
  })

  return externalLink ? (
    <Button
      as="a"
      variant="primary"
      href={externalLink}
      {...props}
      target="__blank"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: ['3.3rem', '4rem', '4.4rem'],
        fontFamily: 'primary',
        fontSize: [1, null, 2, 3],
        fontWeight: 'bold',
        letterSpacing: '1px',
        ...forwardSx
      }}
    >
      {customCtaText || 'ORDER NOW'}
    </Button>
  ) : (
    <form
      onSubmit={handleSubmit}
      sx={{ width: '100%', maxWidth: [null, null, '34rem'] }}
    >
      <Button
        disabled={
          buttonIsLoading ||
          isFetchingAvailability ||
          !isAvailable ||
          comingSoon
        }
        {...props}
        type="submit"
        variant="primary"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: ['3.3rem', '4rem', '4.4rem'],
          fontFamily: 'primary',
          fontSize: [1, null, 2, 3],
          fontWeight: 'bold',
          letterSpacing: '1px',
          ':disabled': { opacity: 0.5 },
          ...forwardSx
        }}
      >
        {(buttonIsLoading || isFetchingAvailability) && (
          <Spinner
            aria-label="Loading..."
            data-testid="spinner"
            size="15"
            color="inherit"
          />
        )}
        {comingSoon &&
          !isFetchingAvailability &&
          !buttonIsLoading &&
          translate('product.coming_soon')}
        {!buttonIsLoading && !isFetchingAvailability && !comingSoon && (
          <Fragment>
            {isAvailable
              ? ctaText || translate('product.add_to_cart')
              : translate('product.sold_out')}
          </Fragment>
        )}
      </Button>

      {apiError && (
        <Text
          color="errorDark"
          variant="textLink"
          mt="1.5rem"
          sx={{ textAlign: 'center' }}
        >
          {apiError.toString()}
        </Text>
      )}

      {availabilityError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {availabilityError.toString()}
        </Text>
      )}
    </form>
  )
}

ProductAddToCartButton.propTypes = {
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string,
  variantId: PropTypes.number.isRequired
}

export default ProductAddToCartButton
