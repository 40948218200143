import useShopify from '~/hooks/shopify/use-shopify'
import { encodeShopifyVariantID } from '~/utils/shopify'

const useVariantAvailability = ({ variantId }) => {
  const query = `
    {
      node(id: "${encodeShopifyVariantID(variantId)}") {
        ... on ProductVariant {
          sku
          availableForSale
          currentlyNotInStock
          quantityAvailable
          product {
            availableForSale
            handle
          }
        }
      }
    }
  `

  const { data, isLoading, error } = useShopify(query)

  // Start by assuming that the product is available. And if something
  // wrong happened in this hook, worse case scenario, it will be handled
  // by Shopify's checkout.
  return {
    isAvailable: data?.node ? checkAvailability(data) : true,
    isFetchingAvailability: isLoading,
    error: error,
    quantityAvailable: data?.node ? data.node.quantityAvailable : null,
    product: data?.node ? data.node.product : null
  }
}

const checkAvailability = data => {
  /**
   * https://shopify.dev/api/storefront/reference/products/productvariant#fields-2021-07
   *
   * availableForSale: will return false if no inventory is available for that variant.
   *
   * currentlyNotInStock: will return true only if the variant inventory quantity is <= 0
   * and overselling is allowed.
   */
  const { availableForSale, currentlyNotInStock: backorderable } = data.node

  return availableForSale || backorderable
}

export default useVariantAvailability
