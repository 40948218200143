/** @jsx jsx */

import { Link, Flex, jsx } from 'theme-ui'
import useSiteMetadataQuery from '~/hooks/graphql/queries/use-site-metadata'
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTiktok,
  IoLogoTwitter,
  IoLogoVimeo,
  IoLogoYoutube
} from 'react-icons/io5'

const FooterSocialButton = ({ children, ...props }) => {
  return (
    <Link
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '2rem',
        height: '2rem',
        ':not(:last-child)': {
          marginRight: '1.6rem'
        },
        svg: {
          width: '100%',
          height: '100%'
        }
      }}
    >
      {children}
    </Link>
  )
}

const FooterSocial = () => {
  const {
    site: {
      siteMetadata: {
        social: { facebook, instagram, twitter, vimeo, youtube, tiktok }
      }
    }
  } = useSiteMetadataQuery()

  return (
    <Flex
      sx={{
        justifyContent: 'flex-start'
      }}
    >
      <FooterSocialButton
        href={facebook}
        target="_blank"
        aria-label="Super73 on Facebook"
        rel="noreferrer"
      >
        <IoLogoFacebook />
      </FooterSocialButton>

      <FooterSocialButton
        href={instagram}
        target="_blank"
        aria-label="Super73 on Instagram"
        rel="noreferrer"
      >
        <IoLogoInstagram />
      </FooterSocialButton>

      <FooterSocialButton
        href={twitter}
        target="_blank"
        aria-label="Super73 on Twitter"
        rel="noreferrer"
      >
        <IoLogoTwitter />
      </FooterSocialButton>

      <FooterSocialButton
        href={vimeo}
        target="_blank"
        aria-label="Super73 on Vimeo"
        rel="noreferrer"
      >
        <IoLogoVimeo />
      </FooterSocialButton>

      <FooterSocialButton
        href={youtube}
        target="_blank"
        aria-label="Super73 on YouTube"
        rel="noreferrer"
      >
        <IoLogoYoutube />
      </FooterSocialButton>
      <FooterSocialButton
        href={tiktok}
        target="_blank"
        aria-label="Super73 on TikTok"
        rel="noreferrer"
      >
        <IoLogoTiktok />
      </FooterSocialButton>
    </Flex>
  )
}

export default FooterSocial
