/** @jsx jsx */
import { Container, Box, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import Nav from '~/components/Nav'
import Footer from '~/components/Footer'
import { ScrollContent } from '../Generic/ScrollSnap'
import { useEffect, useState, useContext, createContext, useRef } from 'react'
import useNotificationBarQuery from '~/hooks/graphql/queries/use-notification-bar'
import Search from '../Generic/Search'
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation'
import useOnClickOutside from '~/hooks/utils/use-on-click-outside'
import { useLocation } from '@reach/router'
import { useCart, utils } from '@chordcommerce/gatsby-theme-performance'
import NotificationBar from '../Notification/Bar'
import aa from 'search-insights'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

aa('init', {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  apiKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY
})

aa('getUserToken', null, (err, algoliaUserToken) => {
  if (err) {
    console.error(err)
    return
  }
  if (!algoliaUserToken) {
    console.log('Setting token')
    aa(
      'setUserToken',
      typeof window !== 'undefined'
        ? JSON.parse(localStorage.getItem('ajs_anonymous_id'))
        : Date.now().toString()
    )
  }
})

const BannerContext = createContext()
export const useBannerContext = () => useContext(BannerContext)

const Layout = ({
  children,
  breadcrumb: { forwardSx, ...breadcrumbProps },
  showBreadcrumbs,
  translucentNavBar,
  noMinHeight
}) => {
  const { checkout, addPromoCode, removePromoCode, cart } = useCart()
  const { getAllCartPromotionsForDisplay } = utils
  const { pathname, search } = useLocation()

  useEffect(() => {
    const matches = ['invoices', 'checkouts', 'orders']
    for (const path of matches) {
      if (pathname.includes(path)) {
        window.location.replace(`https://shop.super73.com${pathname}${search}`)
      }
    }

    if (pathname.includes('discount')) {
      const index = pathname.split('/').indexOf('discount') + 1
      const promoCode = pathname.split('/')[index]
      if (promoCode) {
        addDiscount(promoCode)
      }
    }
  }, [])

  const addDiscount = async promoCode => {
    try {
      await addPromoCode(promoCode)
      await checkout()
    } catch {
      await checkout()
    }
  }

  const mobile = useMatchMedia('(max-width: 1024px)')

  const { pages, popularSearches, trendingSearches } = useNavigationQuery()

  const notificationBars = useNotificationBarQuery()
  const disabledBannerPages = ['/c1x', '/c1x/', '/youth', '/youth/']
  const SHOW_BANNER =
    !disabledBannerPages.includes(pathname) &&
    !!notificationBars.notificationBar

  // const [bannerExpanded, setBannerExpanded] = useState(false)
  const [bannerHeight, setBannerHeight] = useState(60)

  const [showSearch, setShowSearch] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const searchRef = useRef()
  useOnClickOutside(searchRef, () => setShowSearch(false))
  useEffect(() => {
    if (!showSearch) {
      document.body.classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll-mobile')
      document.querySelector('main').classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll-mobile')
      document.querySelector('main').classList.add('no-scroll')
    }
  }, [showSearch])

  useEffect(() => {
    mobile ? setBannerHeight(48) : setBannerHeight(60)
  }, [mobile])
  return (
    <Container
      variant="fullwidth"
      sx={{
        '--nav-bar-height': ['4.9rem', null, '6.9rem', '4.589rem', '8.6rem'],
        overflowX: 'hidden',
        '::before': {
          content: '""',
          display: translucentNavBar ? 'none' : 'block',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: 'var(--nav-bar-height)',
          backgroundColor: 'background',
          zIndex: 10
        }
      }}
    >
      <a
        href="#main"
        sx={{
          left: '50%',
          position: 'absolute',
          transform: 'translateY(-100%)',
          opacity: '0',
          zIndex: '9999',
          ':focus': {
            transform: 'translateY(0%)',
            opacity: '1',
            color: 'white',
            backgroundColor: 'black',
            py: '12px'
          }
        }}
      >
        Skip to Content
      </a>
      {!disabledBannerPages.includes(pathname) && (
        <NotificationBar bannerHeight={bannerHeight} />
      )}

      {showSearch && (
        <Box
          ref={searchRef}
          sx={{
            '--banner-height': bannerHeight + 'px',
            position: 'fixed',
            pointerEvents: showSearch ? 'auto' : 'none',
            backgroundColor: 'white',
            padding: ['20px 25px', null, null, '20px 50px'],
            zIndex: 1000,
            left: 0,
            width: '100%',
            height: ['100%', null, null, expanded ? '100%' : '468px'],
            transition: 'height 0.4s cubic-bezier(.17,.67,.26,1.01)',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.169)',
            overflow: ['auto', null, null, expanded ? 'auto' : 'hidden'],
            animation: 'showSearch 0.4s cubic-bezier(.17,.67,.26,1.01)'
          }}
        >
          <Search
            setShowSearch={setShowSearch}
            setExpanded={setExpanded}
            expanded={expanded}
            popularSeaches={popularSearches}
            trendingSearches={trendingSearches}
          />
        </Box>
      )}

      <BannerContext.Provider value={{ bannerHeight, showBanner: SHOW_BANNER }}>
        <ScrollContent
          showBanner={SHOW_BANNER}
          bannerHeight={bannerHeight}
          as="main"
          aria-label="main-content"
          forwardSx={{
            '--inset-top': '3.2rem',
            minHeight: noMinHeight
              ? null
              : 'calc(100vh - var(--nav-bar-height))'
          }}
        >
          <Nav
            aria-label="nav-bar"
            showBanner={SHOW_BANNER}
            bannerHeight={bannerHeight}
            setShowSearch={setShowSearch}
            pages={pages}
            stickyNavDisabled={disabledBannerPages.includes(pathname)}
          />

          {showBreadcrumbs ? (
            <Container
              variant="fullWidth"
              sx={{
                pt: [
                  'calc(var(--nav-bar-height) + 2.1rem)',
                  'calc(var(--nav-bar-height) + 4.6rem)',
                  'calc(var(--nav-bar-height) + 1.6rem)',
                  'calc(var(--nav-bar-height) + 4rem)'
                ],
                pb: ['2.1rem', '2.9rem', '2.2rem', null, '4.2rem'],
                ...forwardSx
              }}
            />
          ) : null}
          <div id="main">{children}</div>
          <Footer aria-label="footer" />
        </ScrollContent>
      </BannerContext.Provider>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: PropTypes.shape({
    crumbLabel: PropTypes.string,
    forwardSx: PropTypes.object
  }),
  showBreadcrumbs: PropTypes.bool.isRequired,
  translucentNavBar: PropTypes.bool
}

Layout.defaultProps = {
  breadcrumb: { forwardSx: {} },
  showBreadcrumbs: true,
  translucentNavBar: true
}

export default Layout
