/** @jsx jsx */
import PropTypes from 'prop-types'
import { Grid, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import FooterCollapsibleNavigation from '~/components/Footer/FooterCollapsibleNavigation'

const FooterLinks = ({ company, help, info }) => {
  const translate = useTranslate()

  return (
    <Grid
      sx={{
        width: '100%',
        gridTemplateColumns: ['1fr', 'repeat(3, minmax(min-content, 240px))'],
        justifyContent: [null, 'space-evenly', null, 'flex-start'],
        gap: [3, '5%', null, '10%']
      }}
    >
      <FooterCollapsibleNavigation
        name={translate('footer.company.title')}
        links={company}
      />

      <FooterCollapsibleNavigation
        name={translate('footer.help.title')}
        links={help}
      />

      <FooterCollapsibleNavigation
        name={translate('footer.info.title')}
        links={info}
      />
    </Grid>
  )
}

FooterLinks.propTypes = {
  company: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  ),
  help: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  ),
  info: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    })
  )
}

export default FooterLinks
