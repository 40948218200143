/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Flex, jsx } from 'theme-ui'
import { useState } from 'react'
import { Link, navigate } from 'gatsby'
import ChevronRight from '~/assets/images/icons/chevron-right.svg'
import ChevronDown from '~/assets/images/icons/chevron-down.svg'
import { useEffect, useRef } from 'react'
import { useWindowSize } from '~/hooks/utils/use-window-size'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import ProductPreviewCard from '../ProductPreviewCard'

const NavCollectionLink = ({
  collection,
  catalogSlug,
  activeCollection,
  onActive,
  linkSx
}) => {
  const { trackCollectionClicked } = useAnalytics()
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [activeProductPreview, setActiveProductPreview] = useState(null)
  const { width } = useWindowSize()
  const isMobileTablet = width <= 1024
  const containerRef = useRef()
  const containerMeasurements = containerRef.current?.getBoundingClientRect()

  useEffect(() => {
    if (isDetailOpen) onActive()
  }, [isDetailOpen])

  useEffect(() => {
    if (activeCollection !== collection.id) {
      setIsDetailOpen(false)
      setActiveProductPreview(null)
      return
    }

    if (catalogSlug !== 'all-bikes' || !collection.products.length) return

    setActiveProductPreview(collection.products[0].id)
  }, [activeCollection])

  return (
    <li
      sx={{
        display: 'block',
        mb: catalogSlug !== 'all-bikes' && '10px',
        '--preview-card-width': [
          null,
          null,
          null,
          `calc(100vw - ${containerMeasurements?.left ?? 0}px - ${
            containerMeasurements?.width ?? 0
          }px - 0.8rem)`,
          `calc(100vw - ${containerMeasurements?.left ?? 0}px - ${
            containerMeasurements?.width ?? 0
          }px - 4rem)`
        ]
      }}
      ref={containerRef}
    >
      <Flex
        sx={{
          alignItems: 'center',
          ':hover': { fontWeight: 'bold', '> button > svg': { opacity: '1' } }
        }}
      >
        <button
          onClick={() => {
            // TODO this whole logic is very messy and should be abstracted
            if (
              (collection.displayProductDropdown &&
                !isDetailOpen &&
                catalogSlug === 'all-bikes') ||
              (!isDetailOpen && collection.slug === 'collections')
            ) {
              setIsDetailOpen(true)
              return
            } else if (isDetailOpen) {
              setIsDetailOpen(false)
            }
            if (catalogSlug !== 'all-bikes') {
              navigate(`/${catalogSlug}/${collection.slug}`)
            }
            if (collection.slug === 'all-bikes') {
              navigate(`/${collection.slug}`)
            }
          }}
          sx={{
            ...linkSx,
            fontWeight: [
              isDetailOpen && collection.collections ? 'bold' : null,
              null,
              null,
              isDetailOpen ||
              (catalogSlug !== 'all-bikes' && collection.collections)
                ? 'bold'
                : null
            ],
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            lineHeight:
              catalogSlug !== 'all-bikes'
                ? [null, null, null, '150%', '100%']
                : '3rem',
            ':hover': { fontWeight: 'bold', '> button > svg': { opacity: '1' } }
          }}
        >
          {collection.title || collection.name}
        </button>
        {catalogSlug === 'all-bikes' && collection.displayProductDropdown && (
          <button
            tabIndex="-1"
            onClick={() => setIsDetailOpen(!isDetailOpen)}
            sx={{
              display: ['inline'],
              ml: ['1.6rem', null, null, isDetailOpen ? '0.1rem' : '0.3rem'],
              backgroundColor: 'transparent',
              border: 'none',
              ' > svg ': {
                opacity: isDetailOpen ? '1' : '0.5',
                mt: !isDetailOpen ? '4px' : '-4px',
                transition: 'all 300ms ease-in-out'
              }
            }}
          >
            {isDetailOpen ? <ChevronDown /> : <ChevronRight />}
          </button>
        )}
      </Flex>

      {(!collection.products && !collection.collections) ||
      (collection.__typename === 'ContentfulCollection' &&
        !collection.displayProductDropdown) ? null : (
        <ul
          sx={{
            listStyleType: 'none',
            padding: 0,
            height: [
              isDetailOpen ? 'auto' : 0,
              null,
              null,
              isDetailOpen || catalogSlug !== 'all-bikes' ? 'auto' : 0
            ],
            overflow: 'hidden',
            '& > li a': {
              variant: 'text.navLink',
              display: 'block',
              color: ['white', null, null, 'rgba(256,256,256,0.8)'],
              fontSize: ['1.7rem', null, null, 1, 4],
              whiteSpace: ['normal', null, null, 'nowrap'],
              lineHeight:
                catalogSlug !== 'all-bikes'
                  ? [null, null, null, '100%', '80%']
                  : '3rem',
              padding: [
                '1.6rem 1.6rem 1.6rem calc(var(--link-horizontal-inset) * 2)',
                null,
                null,
                catalogSlug === 'all-bikes'
                  ? '0.3rem 1rem 0.3rem calc(var(--link-horizontal-inset) * 2)'
                  : '0.3rem 1rem 0.3rem calc(var(--link-horizontal-inset) * 1.5)',
                catalogSlug === 'all-bikes'
                  ? '1rem 1.6rem 1rem calc(var(--link-horizontal-inset) * 1.5)'
                  : '1rem 1.6rem 1rem var(--link-horizontal-inset)'
              ],
              ':hover': { color: 'white', fontWeight: 'medium' }
            }
          }}
        >
          {collection.products?.map(product => {
            return (
              <li key={product.id}>
                <Link
                  to={`/products/${product.slug}`}
                  aria-label={`${product.slug} navigation menu link to product page`}
                  onClick={event => {
                    if (isMobileTablet) {
                      navigate(`/products/${product.slug}`)
                    }
                    if (
                      catalogSlug !== 'all-bikes' ||
                      activeProductPreview === product.id
                    )
                      return

                    event.preventDefault()
                    setActiveProductPreview(product.id)
                  }}
                  onMouseOver={() =>
                    !isMobileTablet && setActiveProductPreview(product.id)
                  }
                  onFocus={() =>
                    !isMobileTablet && setActiveProductPreview(product.id)
                  }
                  sx={{
                    fontWeight: [
                      null,
                      null,
                      null,
                      activeProductPreview === product.id ? 'bold' : null,
                      null
                    ]
                  }}
                >
                  {product.name}
                </Link>
                {catalogSlug === 'all-bikes' &&
                activeProductPreview === product.id ? (
                  <ProductPreviewCard
                    product={product}
                    isActive={activeProductPreview === product.id}
                  />
                ) : null}
              </li>
            )
          })}

          {collection.collections?.map(coll => {
            return (
              <li key={coll.id}>
                <Link
                  to={`/${catalogSlug}/${coll.slug}`}
                  aria-label={`${catalogSlug} ${coll.slug} navigation menu link`}
                  onClick={() => {
                    trackCollectionClicked({
                      id: coll.id,
                      title: coll.title,
                      slug: coll.slug
                    })
                  }}
                >
                  {coll.title}
                </Link>
              </li>
            )
          })}
          <li>
            <Link
              to={`/${catalogSlug}/${collection.slug}`}
              aria-label={`${catalogSlug} ${collection.slug} navigation menu link`}
              onClick={() => {
                trackCollectionClicked({
                  id: collection.id,
                  title: collection.title,
                  slug: collection.slug
                })
              }}
            >
              All {collection.name ?? collection.slug}
            </Link>
          </li>
        </ul>
      )}
    </li>
  )
}

export default NavCollectionLink
