/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import { generateVariantQueryString } from '~/utils/variantQueryStrings'
import { useLocation } from '@reach/router'

const ProductLink = ({
  variant,
  product,
  externalLink,
  children,
  sx,
  collectionName,
  from,
  lineup,
  price,
  ...props
}) => {
  const { trackProductClicked } = useAnalytics()
  const { pathname } = useLocation()
  const basePath = pathname.split('/')[1]

  const url = `/products/${product.slug}${generateVariantQueryString(
    variant,
    product
  )}`

  const handleClick = () => {
    trackProductClicked({ productHandle: product.slug }).catch(console.log)
  }

  return externalLink ? (
    <a
      href={externalLink}
      onClick={handleClick}
      sx={{
        ...sx
      }}
      aria-label={`${product.name} at ${
        variant?.price || price || ''
      }USD Product Page Link within the ${
        collectionName ?? 'no name'
      } collection on variant ${variant?.name ?? 'unknown variant'}  from the ${
        from ?? 'unknown location'
      } ${
        lineup ? `on the ${lineup} lineup page` : 'not on a lineup page'
      } in the ${basePath} base url path`}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link
      to={url}
      onClick={handleClick}
      aria-label={`${product.name} at ${
        variant?.price || price || ''
      }USD Product Page Link within the ${
        collectionName ?? 'no name'
      } collection on variant ${variant?.name ?? 'unknown'} from the ${
        from ?? 'unknown location'
      } ${
        lineup ? `on the ${lineup} lineup page` : 'not on a lineup page'
      } in the ${basePath} base url path`}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

ProductLink.propTypes = {
  product: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        lineup: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
            lineup: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string
              })
            )
          }).isRequired
        )
      })
    )
  }).isRequired,
  children: PropTypes.node
}

export default ProductLink
