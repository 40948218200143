import { graphql, useStaticQuery } from 'gatsby'

const useBikeFiltersQuery = () => {
  const data = useStaticQuery(graphql`
    {
      bikeFilters: contentfulFilterCategory(slug: { eq: "filter-bike-model" }) {
        id
        filterValues {
          presentation
        }
      }
    }
  `)

  return data
}

export default useBikeFiltersQuery
