/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import LogoSimplified from '~/assets/images/logos/logo-small.svg'

const SiteLogo = () => {
  return (
    <Link
      to="/"
      sx={{
        display: 'inline-block',
        height: '30px',
        '& svg': {
          height: '100%',
          width: 'auto',
          path: { fill: 'white' }
        }
      }}
      aria-label="Home"
    >
      <LogoSimplified />
    </Link>
  )
}

export default SiteLogo
