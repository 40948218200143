/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Box, Flex, jsx } from 'theme-ui'
import MinusIcon from '~/assets/images/icons/minus.svg'
import ChevronRight from '~/assets/images/icons/chevron-right.svg'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import CatalogLinkDropdown from '../CatalogLinkDropdown'
import { useWindowSize } from '~/hooks/utils/use-window-size'

const CatalogLink = ({
  catalog,
  slug,
  noPage,
  collections,
  pages,
  onHover,
  onBlur,
  openId,
  setOpenId,
  setOpenFilter,
  forwardSx = {}
}) => {
  const useMegaMenu = collections?.some(
    col => col.__typename === 'ContentfulLineup'
  )
  const isSLViewport = useMatchMedia('(min-width: 768px)')
  const isOpen = openId === slug
  const { width } = useWindowSize()
  const isMobileTablet = width <= 1024

  useEffect(() => {
    if (isSLViewport) setOpenId(null)
  }, [isSLViewport])

  return (
    <Box
      as="li"
      sx={{
        position: useMegaMenu ? 'static' : 'relative',
        ':not(:last-of-type)': {
          marginBottom: ['3.4rem', null, null, 0]
        },
        '> ul': {
          height: [null, null, null, isOpen ? 'auto' : null]
        },
        ':hover': {
          backgroundColor: [null, null, null, 'black70'],
          transition: 'background-color 0.2s ease',
          '> ul': {
            height: [
              null,
              null,
              null,
              !isMobileTablet && useMegaMenu ? '370px' : null,
              !isMobileTablet && useMegaMenu ? '540px' : null
            ]
          }
        },
        ...forwardSx
      }}
      onMouseLeave={() => {
        if (isOpen) {
          setOpenId(null)
        }
      }}
    >
      <Flex
        sx={{ alignItems: 'center', height: [null, null, null, '100%', null] }}
        onClick={() => {
          if (isOpen) {
            setOpenId(null)
          } else {
            setOpenId(slug)
            onHover()
          }
        }}
        aria-label={`${isOpen ? 'collapse ' : 'expand'} ${catalog} menu`}
      >
        <button
          sx={{
            variant: 'text.navLink',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: ['flex-start', null, null, 'center'],
            alignItems: 'center',
            width: 'auto',
            height: [null, null, null, '100%'],
            color: 'white',
            fontSize: ['1.7rem', null, null, 1, 4],
            fontWeight: isOpen ? 'bold' : null,
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: [
              '0 1.6rem 0 var(--link-horizontal-inset)',
              null,
              null,
              '0 var(--link-horizontal-inset)'
            ],
            whiteSpace: 'nowrap'
          }}
        >
          {catalog}
        </button>
        <button
          tabIndex="-1"
          aria-label={isOpen ? 'collapse menu' : 'expand menu'}
          onClick={() => (isOpen ? setOpenId(null) : setOpenId(slug))}
          sx={{
            display: [
              collections || pages ? 'flex' : 'none',
              null,
              null,
              'none'
            ],
            backgroundColor: 'transparent',
            alignItems: 'center',
            padding: 0,
            p: '5px 20px',
            border: 'none',
            minHeight: '21px',
            ml: 2
          }}
        >
          {isOpen ? <MinusIcon /> : <ChevronRight />}
        </button>
      </Flex>
      <CatalogLinkDropdown
        isOpen={isOpen}
        collections={collections || []}
        pages={pages || []}
        catalogSlug={slug}
        setOpenFilter={setOpenFilter}
      />
    </Box>
  )
}

CatalogLink.propTypes = {
  catalog: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string,
      title: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    })
  ),
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  onHover: PropTypes.func,
  onBlur: PropTypes.func
}

CatalogLink.defaultProps = {
  onHover: () => {},
  onBlur: () => {}
}

export default CatalogLink
