/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, Box, Heading, Grid, IconButton, jsx } from 'theme-ui'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { useEffect, useRef, useState } from 'react'
import NavLinks from '~/components/Nav/Links'
import NavLogo from '~/components/Nav/Logo'
import NavToggle from '~/components/Nav/Toggle'
import SiteLink from '~/components/Nav/SiteLink'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import CatalogLink from '../CatalogLink'
import UserIcon from '~/assets/images/icons/user.svg'
import ShoppingBagIcon from '~/assets/images/icons/shopping-bag.svg'
import SearchIcon from '~/assets/images/icons/search.svg'
import CloseIcon from '~/assets/images/icons/plus-icon.svg'
import { Link } from 'gatsby'
import useOnClickOutside from '~/hooks/utils/use-on-click-outside'
import { Container } from 'theme-ui'
import useBikeFiltersQuery from '~/hooks/graphql/queries/use-bike-filters'

export const NavBar = ({
  pages,
  showBanner,
  bannerHeight,
  setShowSearch,
  stickyNavDisabled
}) => {
  const {
    cart: {
      data: { lineItems: cartItems }
    }
  } = useCart()
  const isSLViewport = useMatchMedia('(min-width: 1024px')
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isNavigationExpanded, setIsNavigationExpanded] = useState(false)
  const [openId, setOpenId] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)
  const [bikeFilter, setBikeFilter] = useState(null)
  const ref = useRef()

  const bikeFilters = useBikeFiltersQuery()

  useEffect(() => {
    if (isMobileNavOpen && !isSLViewport) {
      document.body.classList.add('no-scroll')
      document.querySelector('main').classList.add('no-scroll')
      document.documentElement.classList.add('no-scroll-mobile')
    } else {
      document.body.classList.remove('no-scroll')
      document.querySelector('main').classList.remove('no-scroll')
      document.documentElement.classList.remove('no-scroll-mobile')
    }

    // Automatically close the mobile nav when viewport grows in order
    // to avoid getting an unexpected open nav when viewport shrinks
    if (isMobileNavOpen && isSLViewport) {
      setIsMobileNavOpen(false)
    }
  }, [isSLViewport, isMobileNavOpen])

  useOnClickOutside(ref, () => setOpenId(null))

  return (
    <Container
      as="header"
      variant="fullWidth"
      ref={ref}
      sx={{
        position: stickyNavDisabled ? 'relative' : 'fixed',
        marginBottom: stickyNavDisabled
          ? 'calc(var(--nav-bar-height) * -1)'
          : 0,
        top: showBanner ? bannerHeight + 'px' : 0,
        left: 0,
        width: '100%',
        backgroundColor: [
          'black70',
          null,
          isNavigationExpanded ? 'black40' : 'black70'
        ],
        p: 0,
        transform: 'none',
        overflow: 'visible',
        zIndex: 'navBar',
        height: 'var(--nav-bar-height)',
        transition: 'background-color 0.2s ease, top 0.25s linear'
      }}
    >
      {openFilter && (
        <Flex
          sx={{
            position: 'fixed',
            background: 'transparent',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              backgroundColor: 'white',
              padding: '40px',
              gap: 3,
              fontFamily: 'secondary',
              width: '75%',
              maxWidth: '400px',
              position: 'relative',
              zIndex: 1,
              boxShadow: '0 0 10px 1px rgba(0,0,0,0.2)'
            }}
          >
            <IconButton
              onClick={() => {
                setOpenFilter(false)
                setBikeFilter(null)
              }}
              sx={{
                transform: 'rotate(45deg)',
                position: 'absolute',
                top: '0px',
                right: '0px',
                padding: '10px',
                width: 'auto',
                height: 'auto',
                cursor: 'pointer'
              }}
            >
              <CloseIcon sx={{ width: '25px', height: '25px' }} />
            </IconButton>
            <Heading>SHOP BY BIKE</Heading>
            <select
              id="models"
              onChange={e =>
                e.target.value !== 'default'
                  ? setBikeFilter(e.target.value)
                  : setBikeFilter(null)
              }
              sx={{
                padding: '10px 20px',
                fontSize: 4,
                backgroundColor: 'white',
                border: '1px solid',
                fontFamily: 'body',
                letterSpacing: '0.6px'
              }}
            >
              <option value="default">Model</option>
              {bikeFilters.bikeFilters.filterValues.map(fv => (
                <option key={fv.presentation} value={fv.presentation}>
                  {fv.presentation?.toUpperCase()}
                </option>
              ))}
            </select>
            <a
              href={
                bikeFilter
                  ? `/accessories/accessories-all?filters=${bikeFilter}&byModel=true`
                  : null
              }
              sx={{
                cursor: bikeFilter ? 'pointer' : 'not-allowed',
                backgroundColor: bikeFilter ? 'black' : 'rgba(0,0,0,0.7)',
                transition: 'background-color 0.3s ease',
                color: 'white',
                fontSize: 5,
                textAlign: 'center',
                padding: '10px 20px'
              }}
            >
              GO
            </a>
          </Flex>
          <Box
            onClick={() => {
              setOpenFilter(false)
              setBikeFilter(null)
            }}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 0
            }}
          />
        </Flex>
      )}
      <Grid
        as="nav"
        aria-label="Site navigation"
        columns={[3, null, 'auto minmax(0, 1fr) auto']}
        sx={{
          margin: '0 auto',
          alignItems: 'center',
          px: 'var(--horizontal-inset)',
          height: '100%',
          gap: [null, null, 0]
        }}
      >
        <NavToggle onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
          {useTranslate('navigation.menu')}
        </NavToggle>

        <Heading>
          <NavLogo />
        </Heading>

        <NavLinks
          isOpen={isMobileNavOpen}
          bannerHeight={bannerHeight}
          showBanner={showBanner}
        >
          {pages.map(page =>
            page.__typename === 'ContentfulCatalog' ||
            page.__typename === 'ContentfulLineup' ? (
              <CatalogLink
                catalog={page.name}
                slug={page.slug}
                noPage={page.noPage}
                collections={page.collections}
                pages={page.featuredPages}
                onHover={() => setIsNavigationExpanded(true)}
                onBlur={() => setIsNavigationExpanded(false)}
                key={page.id}
                openId={openId}
                setOpenId={setOpenId}
                setOpenFilter={setOpenFilter}
                forwardSx={
                  page.slug === 'accessories'
                    ? { order: ['-2', null, 0] }
                    : page.slug === 'all-bikes'
                    ? { order: ['-3', null, 0] }
                    : {}
                }
              />
            ) : (
              <SiteLink
                page={page}
                onHover={() => setIsNavigationExpanded(true)}
                onBlur={() => setIsNavigationExpanded(false)}
                key={page.id}
                openId={openId}
                setOpenId={setOpenId}
              />
            )
          )}
        </NavLinks>

        <Flex
          as="ul"
          variant="list.bare"
          dir="row"
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: 'white',
            a: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            'svg, button': {
              width: ['1.8rem', null, null, '2.4rem'],
              height: ['1.8rem', null, null, '2.4rem'],
              verticalAlign: 'middle'
            }
          }}
        >
          <li>
            <IconButton
              sx={{
                padding: 0,
                cursor: 'pointer',
                mr: ['clamp(8px, 4vw, 18px)', null, 'clamp(8px, 2vw, 32px)']
              }}
              onClick={() => setShowSearch(true)}
              aria-label="Search Bar icon to open search"
              tabIndex="0"
            >
              <SearchIcon />
            </IconButton>
          </li>
          <li>
            <Link
              to="/account"
              aria-label="Account"
              sx={{
                mr: ['clamp(8px, 4vw, 18px)', null, 'clamp(8px, 2vw, 32px)']
              }}
            >
              <UserIcon />
            </Link>
          </li>
          <li>
            <Link
              to="/cart"
              as="li"
              aria-label="Shopping Cart"
              sx={{
                position: 'relative',
                '::before': {
                  content: '""',
                  position: 'absolute',
                  top: '2px',
                  right: 0,
                  display: cartItems?.length ? 'block' : 'none',
                  width: '10px',
                  height: '10px',
                  backgroundColor: 'red',
                  borderRadius: '50%'
                }
              }}
            >
              <ShoppingBagIcon />
            </Link>
          </li>
        </Flex>
      </Grid>
    </Container>
  )
}

NavBar.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
      collections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string,
          title: PropTypes.string,
          products: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired
            })
          )
        })
      ),
      featuredPages: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired
        })
      ),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired
        })
      )
    })
  )
}

export default NavBar
