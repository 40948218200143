/** @jsx jsx */
import React from 'react'
import { Box, Input, jsx, Label } from 'theme-ui'

const FormInputWithLabel = React.forwardRef(
  ({ name, label, ...props }, ref) => {
    return (
      <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
        <Input
          sx={{
            fontSize: 1,
            letterSpacing: '1px',
            width: '100%',
            marginBottom: ['0.75rem', null, '0'],
            paddingLeft: 0,
            variant: 'text.body',
            ':focus': {
              outline: 'none',
              borderColor: 'rgba(256,256,256,0.5)'
            },
            '::placeholder': {
              variant: 'text.link',
              textDecoration: 'none',
              opacity: 0
            },
            ':not(:placeholder-shown)': {},
            ':not(:placeholder-shown) + label': {
              opacity: 1,
              transform: 'translateY(-20px)'
            },
            border: 0,
            borderBottom: '1px solid',
            borderColor: 'rgba(185, 185, 185, 0.5)',
            transition: 'border-color 0.25s ease',
            borderRadius: 0
          }}
          name={name}
          ref={ref}
          {...props}
        />

        <Label
          htmlFor={name}
          sx={{
            variant: 'text.link',
            fontSize: ['1.1rem', null, 2, null],
            position: 'absolute',
            top: ['-5px', null, '-7px'],
            left: 0,
            transition: '0.15s ease-out',
            opacity: '1',
            transform: 'translateX(5px)',
            pointerEvents: 'none',
            textTransform: 'none',
            color: 'textInverted',
            textDecoration: 'none'
          }}
        >
          {label}
        </Label>
      </Box>
    )
  }
)
export default FormInputWithLabel
