/**@jsx jsx */
import { Box, Flex, Text, IconButton, jsx } from 'theme-ui'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  SearchBox,
  connectHits,
  connectStateResults,
  Configure
} from 'react-instantsearch-dom'
import ImgLoader from '../ImgLoader'
import CloseIcon from '~/assets/images/icons/close.svg'
import { Fragment, useEffect, useState } from 'react'
import ProductLink from '~/components/Product/Link'
import NavLogo from '~/components/Nav/Logo'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import Hit from './Hit'
import aa from 'search-insights'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const Hits = connectHits(({ hits, setExpanded, expanded }) => {
  const isDesktop = useMatchMedia('(min-width: 1024px)')

  const [hitsToShow, setHitsToShow] = useState(hits.slice(0, 4))
  const [showHits, setShowHits] = useState(false)
  useEffect(() => {
    if (expanded || !isDesktop) {
      setHitsToShow(hits)
    } else {
      setHitsToShow(hits.slice(0, 4))
    }
  }, [expanded, hits, isDesktop])
  useEffect(() => {
    setTimeout(() => {
      setShowHits(true)
    }, 450)
  }, [])

  return (
    <Flex
      sx={{
        flexDirection: ['column', null, null, 'row'],
        width: ['auto', null, null, '80%'],
        gap: [null, null, null, '25px'],
        flexWrap: [null, null, null, 'wrap'],
        opacity: showHits ? 1 : 0,
        transition: '0.25s ease'
      }}
    >
      {hitsToShow.map((hit, i) => (
        <Hit hit={hit} key={hit.name + i} />
      ))}
      {!expanded && hits.length > 4 && isDesktop && (
        <button
          sx={{
            width: 'auto',
            variant: 'responsive.normalLaptopUp',
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            fontSize: 4,
            fontFamily: 'body',
            cursor: 'pointer'
          }}
          onClick={() => setExpanded(true)}
        >
          See All Results
        </button>
      )}
    </Flex>
  )
})

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      searchState && searchState.query ? (
        children
      ) : null
    ) : (
      <Fragment>
        <div sx={{ display: 'flex', alignItems: 'center' }}>
          No results found for "{searchState.query}"
        </div>
        <div />
      </Fragment>
    )
)

const PopularSearches = ({ data, searchValue }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        display: [searchValue ? 'none' : 'flex', null, null, 'flex']
      }}
    >
      <Text
        sx={{
          color: '#7e8488',
          fontWeight: 'medium',
          fontSize: '1.8rem',
          letterSpacing: '0.01em'
        }}
      >
        Popular Searches
      </Text>
      <Flex sx={{ flexDirection: 'column' }}>
        {data?.map(product => (
          <ProductLink
            key={product.name}
            product={product}
            sx={{ fontSize: 5, letterSpacing: '0.01em', mt: '15px' }}
          >
            {product.name}
          </ProductLink>
        ))}
      </Flex>
    </Flex>
  )
}

const TrendingSearches = ({ data, searchValue }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mt: ['50px', null, null, 0],
        width: [null, null, null, '45%'],
        display: ['flex', null, null, searchValue ? 'none' : 'flex']
      }}
    >
      <Text
        sx={{
          color: '#7e8488',
          fontWeight: 'medium',
          fontSize: '1.8rem',
          letterSpacing: '0.01em'
        }}
      >
        Trending Searches
      </Text>
      <Flex sx={{ gap: '15px' }}>
        {data?.map(variant => (
          <ProductLink
            key={variant.name}
            product={variant.product[0]}
            sx={{
              mb: '15px',
              width: '50%',
              maxheight: '75px',
              cursor: 'pointer',
              '& *': { cursor: 'pointer !important' }
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column'
              }}
            >
              <Flex
                sx={{
                  position: 'relative',
                  mb: '10px',
                  maxWidth: [null, null, null, '160px'],
                  height: ['100px', '194px', null, '90px'],
                  overflow: 'hidden',
                  alignItems: 'center'
                }}
              >
                <ImgLoader
                  image={variant.mainImage}
                  alt={variant.description ?? variant.title}
                  imgStyle={{
                    objectFit: 'contain'
                  }}
                />
              </Flex>
              <Text sx={{ fontSize: 3, letterSpacing: '0.01em' }}>
                {variant.name}
              </Text>
            </Flex>
          </ProductLink>
        ))}
      </Flex>
    </Flex>
  )
}

const Search = ({
  setShowSearch,
  setExpanded,
  expanded,
  popularSeaches,
  trendingSearches
}) => {
  aa('getUserToken', null, (err, algoliaUserToken) => {
    if (err) {
      console.error(err)
      return
    }
    if (!algoliaUserToken) {
      console.log('setting token')
      aa(
        'setUserToken',
        typeof window !== 'undefined'
          ? JSON.parse(localStorage.getItem('ajs_anonymous_id'))
          : Date.now().toString()
      )
    }
  })

  const [displaySearch, setDisplaySearch] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  useEffect(() => {
    setTimeout(() => {
      setDisplaySearch(true)
    }, 400)
  }, [])

  useEffect(() => {
    if (!searchValue) {
      setExpanded(false)
    }
  }, [searchValue])

  return (
    <Box
      sx={{
        '--search-box-height': '50px',
        paddingBottom: ['100px', null, null, '50px'],
        '.ais-SearchBox-loadingIndicator': {
          position: 'absolute',
          right: '15px',
          top: ['55%', '53%'],
          transform: 'translateY(-50%)'
        },
        '.ais-SearchBox': {
          width: '100%',
          maxWidth: [null, null, null, '600px'],

          position: 'relative',
          height: 'var(--search-box-height)',
          transform: displaySearch ? 'translateX(0)' : 'translateX(-25px)',
          opacity: displaySearch ? 1 : 0,
          transition: '0.6s cubic-bezier(.17,.67,.26,1.01)',

          button: {
            position: 'absolute',
            cursor: 'pointer',
            backgroundColor: '#e4e4e5',
            left: '5px',
            top: '50%',
            transform: 'translateY(-50%)',
            border: 'none',
            borderRadius: '50%',
            padding: 0,
            height: 'calc(var(--search-box-height) - 10px)',
            width: 'calc(var(--search-box-height) - 10px)',
            '&:not([hidden])': {
              display: 'flex'
            },
            justifyContent: 'center',
            alignItems: 'center',
            svg: {
              width: '45%',
              height: 'auto',
              path: {
                fill: '#3f3e3f',
                stroke: '#3f3e3f'
              }
            },
            '&.ais-SearchBox-reset svg': {
              width: '40%',
              height: 'auto',
              path: {
                fill: '#3f3e3f',
                stroke: 'transparent'
              }
            }
          },
          'form, input': {
            width: '100%',
            height: '100%'
          },
          input: {
            borderRadius: '50px',
            border: 'none',
            boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
            paddingLeft: 'calc(var(--search-box-height) + 5px)',
            outline: 'none',
            fontFamily: 'body',
            fontSize: 4,
            letterSpacing: '0.01em',
            '::placeholder': {
              color: '#e5e4e4',
              opacity: 1
            }
          }
        }
      }}
    >
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
      >
        <Configure clickAnalytics />
        <Flex
          sx={{
            justifyContent: ['center', null, null, 'space-between'],
            alignItems: 'center',
            mb: '20px'
          }}
        >
          <Box
            sx={{
              variant: 'responsive.normalLaptopUp',
              path: {
                fill: 'black !important'
              }
            }}
          >
            <NavLogo />
          </Box>
          <SearchBox
            showLoadingIndicator
            autoFocus
            translations={{ placeholder: 'search' }}
            onChange={e => setSearchValue(e.target.value)}
            onReset={() => setSearchValue(null)}
          />
          <IconButton
            onClick={() => setShowSearch(false)}
            aria-label="Icon to close search bar"
            sx={{
              padding: 0,
              transform: displaySearch
                ? 'translateX(0) rotate(45deg)'
                : 'translateX(25px) rotate(45deg)',
              opacity: displaySearch ? 1 : 0,
              transition: '0.6s cubic-bezier(.17,.67,.26,1.01)',
              width: '20px',
              ml: '10px',
              cursor: 'pointer',
              svg: { stroke: '#5c5f61', width: '100%', height: 'auto' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Flex>
        <Flex
          sx={{
            px: '20px',
            opacity: displaySearch ? 1 : 0,
            transform: displaySearch ? 'translateY(0)' : 'translateY(10px)',
            transition: '0.6s cubic-bezier(.17,.67,.26,1.01)',
            flexDirection: ['column', null, null, 'row'],
            justifyContent: ['flex-start', null, null, 'space-between'],
            maxWidth: [null, null, null, searchValue ? '100%' : '860px'],
            marginLeft: [null, null, null, 'auto'],
            marginRight: [null, null, null, 'auto']
          }}
        >
          <PopularSearches data={popularSeaches} searchValue={searchValue} />
          <Results>
            <Hits setExpanded={setExpanded} expanded={expanded} />
          </Results>
          <TrendingSearches data={trendingSearches} searchValue={searchValue} />
        </Flex>
      </InstantSearch>
    </Box>
  )
}
export default Search
