/** @jsx jsx */
import { Box, Container, Grid, jsx } from 'theme-ui'
import FooterLinks from '~/components/Footer/Links'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import FooterSocial from '~/components/Footer/Social'
import useFooterQuery from '~/hooks/graphql/queries/use-footer'
import SiteLogo from '~/components/Generic/Logo'
import FooterDataConsent from './DataConsent'

export const Footer = () => {
  const { company, help, info } = useFooterQuery()

  return (
    <Box
      as="footer"
      sx={{
        color: 'textInverted',
        padding: ['3.2rem 0.8rem', '3.2rem 1.6rem', null, '6rem 0'],
        backgroundColor: '#111111'
      }}
    >
      <Container>
        <Grid
          sx={{
            gridTemplateColumns: [
              '1fr',
              '1fr 1fr',
              null,
              'minmax(200px, 240px) minmax(280px, 417px) minmax(380px, 1fr)'
            ],
            alignItems: 'center',
            gap: [4, '5rem 4%', null, null, '5rem 10%']
          }}
        >
          <Box sx={{ height: '100%' }}>
            <Box
              mb="4"
              sx={{
                a: { height: ['4.3rem', '4.5rem', null, '4rem', '4.5rem'] }
              }}
            >
              <SiteLogo />
            </Box>

            <Box mb="5">
              {company.companyAddress &&
                company.companyAddress.childMarkdownRemark && (
                  <address
                    sx={{
                      variant: 'text.small',
                      fontStyle: 'normal',
                      fontWeight: 'medium',
                      marginBottom: 4,
                      p: {
                        m: '20px 0'
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: company.companyAddress.childMarkdownRemark.html
                    }}
                  />
                )}
            </Box>

            <FooterSocial />
          </Box>

          <Box>
            <FooterNewsletterSignup />
          </Box>

          <Box
            sx={{
              width: '100%',
              gridColumn: [null, 'span 2', null, 'initial'],
              justifySelf: [null, 'center']
            }}
          >
            <FooterLinks
              company={company.pages}
              help={help.pages}
              info={info.pages}
            />
          </Box>
        </Grid>
        <FooterDataConsent />
      </Container>
    </Box>
  )
}

export default Footer
