/** @jsx jsx */
import { Button, jsx } from 'theme-ui'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { IoChevronForwardSharp, IoChevronDownSharp } from 'react-icons/io5'
import { Link } from 'gatsby'
import { useWindowSize } from '~/hooks/utils/use-window-size'

const FooterCollapsibleNavigation = ({ name, links, initialCollapsed }) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed)
  const { width } = useWindowSize()

  return (
    <nav sx={{ width: '100%' }}>
      <Button
        type="button"
        variant="clear"
        onClick={() => setIsCollapsed(!isCollapsed)}
        tabIndex={width < 577 ? 0 : -1}
        sx={{
          width: '100%',
          variant: 'text.small',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          padding: '0.8rem 0',
          fontSize: [2, null, 3, null]
        }}
        aria-label={
          width < 577
            ? isCollapsed
              ? `Expand ${name} section`
              : `Collapse ${name} section`
            : `${name} footer section label`
        }
      >
        {name}

        <div
          sx={{
            display: ['inline', 'none'],
            paddingTop: '4px',
            marginLeft: '1.6rem'
          }}
        >
          {isCollapsed ? <IoChevronForwardSharp /> : <IoChevronDownSharp />}
        </div>
      </Button>

      <ul
        sx={{
          display: [isCollapsed ? 'none' : 'block', 'block'],
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          marginTop: 3
        }}
      >
        {links.map(({ __typename, title, slug, url }, i) => (
          <li
            key={slug ? slug : `${title}-${i}`}
            sx={{
              variant: 'text.small',
              fontSize: [2, null, 3, null],
              color: 'textMutedInverted',
              ':not(:last-of-type)': {
                marginBottom: 4
              },
              'a:hover': {
                color: 'white'
              }
            }}
          >
            {__typename === 'ContentfulExternalLink' ? (
              <a
                aria-label={`Footer link to ${title}`}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </a>
            ) : slug === 'dealer-locator' ? (
              <a
                aria-label={`Footer link to ${title}`}
                href={`https://super73.com/dealer-locator`}
                rel="noopener noreferrer"
              >
                {title}
              </a>
            ) : (
              <Link
                aria-label={`Footer link to ${title} at ${slug}`}
                to={`/${slug}`}
              >
                {title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

FooterCollapsibleNavigation.propTypes = {
  name: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string
    })
  ).isRequired,
  initialCollapsed: PropTypes.bool
}

FooterCollapsibleNavigation.defaultProps = {
  initialCollapsed: true
}

export default FooterCollapsibleNavigation
