/** @jsx jsx */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import CatalogLinkDropdown from '../CatalogLinkDropdown'
import { useEffect } from 'react'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import ChevronRight from '~/assets/images/icons/chevron-right.svg'
import MinusIcon from '~/assets/images/icons/minus.svg'
import { useWindowSize } from '~/hooks/utils/use-window-size'

const SiteLink = ({ page, openId, setOpenId }) => {
  const isSLViewport = useMatchMedia('(min-width: 1024px)')
  const isOpen = openId === page.slug
  const { width } = useWindowSize()
  const isMobileTablet = width <= 1024
  const isCollection = page.__typename === 'ContentfulCollection'

  useEffect(() => {
    if (isSLViewport) setOpenId(null)
  }, [isSLViewport])

  return (
    <li
      sx={{
        position: ['static', null, null, 'relative'],
        minWidth: 0,
        ':not(:last-of-type)': {
          marginBottom: ['3.4rem', null, null, 0]
        },
        ':hover': {
          backgroundColor: [null, null, null, 'black70'],
          transition: 'background-color 0.2s ease',
          '& > a': { color: 'red' }
        },
        ':first-of-type > a': {
          pt: '3.2rem'
        }
      }}
    >
      {page.__typename === 'ContentfulExternalLink' ? (
        <Flex
          sx={{
            alignItems: 'center',
            height: [null, null, null, '100%', null]
          }}
        >
          <button
            onClick={() => {
              if (isOpen) {
                navigate(page.url)
              } else {
                setOpenId(page.slug)
              }
            }}
            sx={{
              variant: 'text.navLink',
              display: 'flex',
              justifyContent: [null, null, null, 'center'],
              alignItems: 'center',
              height: [null, null, null, '100%'],
              color: 'white',
              fontWeight: isOpen ? 'bold' : null,
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              padding: [
                '0 0 0 6rem',
                null,
                null,
                '0 var(--link-horizontal-inset)',
                null
              ],
              whiteSpace: 'nowrap',
              fontSize: ['1.7rem', null, null, 1, 4]
            }}
          >
            {page.title}
          </button>
          <button
            onClick={() => (isOpen ? setOpenId(null) : setOpenId(page.slug))}
            sx={{
              display: [
                page.relatedPages ? 'flex' : 'none',
                null,
                null,
                'none'
              ],
              alignItems: 'center',
              backgroundColor: 'transparent',
              padding: 0,
              px: '20px',
              pt: '5px',
              minHeight: '21px',
              border: 'none'
            }}
          >
            {isOpen ? <MinusIcon /> : <ChevronRight />}
          </button>
        </Flex>
      ) : (
        <Flex
          sx={{
            alignItems: 'center',
            height: [null, null, null, '100%', null]
          }}
        >
          <button
            onClick={() => {
              if (isOpen || !page.relatedPages?.length || isCollection) {
                isCollection && page.lineup && page.lineup[0]
                  ? navigate(`/${page.lineup[0].slug}/${page.slug}`)
                  : navigate(`/${page.slug}`)
              } else {
                setOpenId(page.slug)
              }
            }}
            sx={{
              variant: 'text.navLink',
              display: 'flex',
              justifyContent: [null, null, null, 'center'],
              alignItems: 'center',
              height: [null, null, null, '100%'],
              color: 'white',
              fontWeight: isOpen ? 'bold' : null,
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              padding: [
                '0 0 0 6rem',
                null,
                null,
                '0 var(--link-horizontal-inset)',
                null
              ],
              whiteSpace: 'nowrap',
              fontSize: ['1.7rem', null, null, 1, 4]
            }}
          >
            {page.title}
          </button>
          <button
            onClick={() => (isOpen ? setOpenId(null) : setOpenId(page.slug))}
            id={`expand-${page.slug}`}
            sx={{
              display: [
                page.relatedPages ? 'block' : 'none',
                null,
                null,
                'none'
              ],
              backgroundColor: 'transparent',
              padding: 0,
              px: '20px',
              pt: '5px',
              border: 'none'
            }}
          >
            {isOpen ? <MinusIcon /> : <ChevronRight />}
          </button>
        </Flex>
      )}

      {page.relatedPages && (
        <CatalogLinkDropdown
          isOpen={isOpen}
          catalogSlug=""
          pages={page.relatedPages}
        />
      )}
    </li>
  )
}

SiteLink.propTypes = {
  page: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string
  }),
  onHover: PropTypes.func,
  onBlur: PropTypes.func
}

SiteLink.defaultProps = {
  onHover: () => {},
  onBlur: () => {}
}

export default SiteLink
