/**@jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import { Link } from 'gatsby'
import { connectHitInsights } from 'react-instantsearch-dom'
import aa from 'search-insights'
import { Flex, Box, Text, jsx } from 'theme-ui'
import ImgLoader from '../ImgLoader'

const HitComponent = ({ hit, insights }) => {
  const { trackProductClicked } = useAnalytics()
  const handleClick = slug => {
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'Search Result Clicked'
    })
    trackProductClicked({ productHandle: slug }).catch(console.log)
  }

  return (
    <Link
      to={`${hit.link}/?objectID=${hit.objectID}&queryID=${hit.__queryID}`}
      aria-label={hit.name}
      onClick={() => handleClick(hit.link.split('/').pop())}
      sx={{
        cursor: 'pointer',
        '& *': { cursor: 'pointer !important' },
        width: ['auto', null, null, '18%'],
        maxWidth: [null, null, null, '260px']
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          mb: '15px'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            paddingBottom: '100%',
            mb: '10px',
            variant: 'responsive.normalLaptopUp'
          }}
        >
          <ImgLoader
            src={hit.imageUrl}
            alt={hit.description ?? 'search result'}
            forwardSx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
        <Text sx={{ fontSize: [5, null, null, 4], letterSpacing: '0.01em' }}>
          {hit.name}
        </Text>
      </Flex>
    </Link>
  )
}

const Hit = connectHitInsights(aa)(HitComponent)
export default Hit
