/** @jsx jsx */
import { Box } from '@theme-ui/components'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import useMatchMedia from '~/hooks/utils/useMatchMedia'

// Utility to easily apply the corresponding styles according to the viewport
const LayoutVariant = {
  mobile: (isOpen, bannerHeight, showBanner) => ({
    '--link-horizontal-inset': ['6rem'],
    position: 'absolute',
    top: 'var(--nav-bar-height)',
    left: isOpen ? 0 : '-100%',
    width: '100%',
    maxWidth: [null, '36rem'],
    height: showBanner
      ? `calc(100vh - var(--nav-bar-height) - ${bannerHeight}px)`
      : `calc(100vh - var(--nav-bar-height))`,
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem 0',
    backgroundColor: 'black70',
    transition: 'left 0.5s ease-in-out',
    overFlowY: 'scroll'
  }),
  desktop: () => ({
    '--link-horizontal-inset': [null, null, null, '1.4rem', '3.3rem'],
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: 0,
    height: '100%'
  })
}

const NavLinks = ({ children, isOpen, bannerHeight, showBanner, count }) => {
  const isSLViewport = useMatchMedia('(min-width: 1024px)')

  const variant = !isSLViewport
    ? LayoutVariant.mobile(isOpen, bannerHeight, showBanner)
    : LayoutVariant.desktop(count)

  return (
    <Box
      as="ul"
      sx={{
        ...variant,
        color: 'textInverted',
        listStyleType: 'none',
        overflow: ['auto', null, null, 'visible'],
        overscrollBehavior: 'contain',
        '> li:last-child': {
          mb: ['40px', 0, 0, 0, 0]
        },
        paddingBottom: [
          'max(env(safe-area-inset-bottom), var(--nav-bar-height))',
          null,
          null,
          0
        ]
      }}
    >
      {children}
    </Box>
  )
}

NavLinks.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  count: PropTypes.number
}

export default NavLinks
