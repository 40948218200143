/** @jsx jsx */
import { Fragment, useState } from 'react'
import { jsx } from 'theme-ui'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { useEffect } from 'react'

const ImgLoader = ({
  image,
  src = null,
  alt = '',
  bgColor = 'background',
  startPercent = 70,
  imgStyle,
  disabled = true,
  forwardSx = {}
}) => {
  const [loaded, setLoaded] = useState(false)
  const [loaderWidth, setLoaderWidth] = useState(0)
  const timeout = src ? 3000 : 500

  useEffect(() => {
    setLoaderWidth(startPercent)
  }, [startPercent])

  // If image onLoad doesn't fire after <timeout> seconds, manually set as completed
  useEffect(() => {
    setTimeout(() => {
      setLoaderWidth(100)
      setTimeout(() => {
        setLoaded(true)
      }, 500)
    }, timeout)
  }, [])

  return (
    <Fragment>
      <p
        sx={{
          margin: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          width: '70px',
          height: '2px',
          backgroundColor: 'rgba(0,0,0,0.2)',
          opacity: loaded ? 0 : 1,
          transition: 'opacity 0.4s ease',
          zIndex: 2,
          pointerEvents: 'none',
          ':after': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            height: '100%',
            backgroundColor: 'black',
            width: loaderWidth + '%',
            transition: loaderWidth < 100 ? 'width 4s ease' : 'width 1s ease'
          }
        }}
      />
      <p
        sx={{
          margin: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundColor: bgColor,
          opacity: loaded ? 0 : 1,
          transition: 'opacity 0.4s ease',
          zIndex: 1
        }}
      />

      {src ? (
        <img
          src={src}
          alt={alt}
          loading="lazy"
          sx={{
            opacity: loaded ? 1 : 0,
            transition: 'opacity 0.4s ease',
            transitionDelay: '0.5s',
            ...forwardSx,
            ...imgStyle
          }}
        />
      ) : (
        <div
          sx={{
            opacity: loaded ? 1 : 0,
            transition: 'opacity 0.4s ease',
            transitionDelay: '0.5s',
            ...forwardSx
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={alt}
            sx={{
              ...forwardSx
            }}
            imgStyle={imgStyle}
          />
        </div>
      )}
    </Fragment>
  )
}

export default ImgLoader
