/** @jsx jsx */
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Flex, Text, jsx } from 'theme-ui'

const ProductOptionSelector = ({
  title,
  type,
  options,
  handleSelection,
  currentVariant,
  alignment,
  reversed,
  size,
  labelSx,
  optionSx,
  previewCard,
  gap = '1.6rem'
}) => {
  return (
    <Flex
      as="nav"
      sx={{
        flexDirection: reversed ? 'column-reverse' : 'column',
        flexWrap: 'nowrap',
        alignItems:
          alignment === 'center'
            ? 'center'
            : alignment === 'left'
            ? 'flex-start'
            : 'flex-end',
        width: '100%'
      }}
      aria-label="Available colors"
    >
      {type !== 'Tire' && (
        <Text as="p" sx={{ mb: 2, ...labelSx }}>
          {previewCard && currentVariant
            ? currentVariant.optionValues[0].name
            : title}
        </Text>
      )}

      <Flex
        sx={{
          variant: 'list.bare',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent:
            alignment === 'center'
              ? 'center'
              : alignment === 'left'
              ? 'flex-start'
              : 'flex-end',
          justifyItems: 'center',
          flexDirection: type === 'Tire' ? 'column' : 'row',
          mt: type === 'Tire' ? '3px' : null,
          '[data-type="color"]:checked + label': {
            borderColor: 'white',
            boxShadow: ' 0 0 0 2px black'
          }
        }}
      >
        {options.map((option, index) => (
          <Fragment key={option.slug}>
            <input
              type="radio"
              name={title}
              value={option.slug}
              defaultChecked={index === 0}
              data-type={type}
              onChange={({ target: { checked } }) =>
                checked ? handleSelection(option) : null
              }
              sx={{
                clipPath: 'inset(100%)',
                clip: 'rect(1px, 1px, 1px, 1px)',
                width: '1px',
                height: '1px',
                overflow: 'hidden',
                position: 'absolute',
                whiteSpace: 'nowrap',
                ':not([data-type="color"]) + label:hover': {
                  backgroundColor: 'grey70'
                },
                ':checked + label': {
                  color: 'white',
                  border: type === 'color' ? '1px solid black' : 0
                },
                ':checked:not([data-type="color"]) + label': {
                  backgroundColor: '#808080'
                }
              }}
              id={`${type}-${option.slug}`}
            />

            <Flex
              as="label"
              htmlFor={`${type}-${option.slug}`}
              sx={{
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                width: type === 'Tire' ? '88%' : previewCard ? '20px' : size,
                minWidth:
                  type === 'Tire' ? '75px' : previewCard ? '20px' : size,
                height: type === 'Tire' ? '2rem' : previewCard ? '20px' : size,
                padding: 0,
                mr: type === 'Tire' ? 0 : previewCard ? '5px' : gap,
                mb: [gap, null, 0, null],
                m: type === 'Tire' ? '0 auto' : null,
                fontSize: type === 'Tire' ? '11px' : 4,
                backgroundColor:
                  type === 'color' ? option.optionalRendering : 'transparent',
                borderRadius: type === 'Tire' ? 0 : '50%',
                border: type === 'color' ? '2px solid rgba(0, 0, 0, 0.2)' : '0',

                cursor: 'pointer',
                ':last-of-type': {
                  mr: type === 'Tire' ? null : 0,
                  mt: type === 'Tire' ? '3px' : null
                },
                ...optionSx
              }}
              title={option.name}
              aria-label={`${option.name}`}
            >
              {type === 'color' ? '' : option.presentation || option.name}
            </Flex>
          </Fragment>
        ))}
      </Flex>
    </Flex>
  )
}

ProductOptionSelector.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.any,
      slug: PropTypes.string,
      optionalRendering: PropTypes.string
    })
  ).isRequired,
  handleSelection: PropTypes.func.isRequired,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  reversed: PropTypes.bool,
  size: PropTypes.any,
  labelSx: PropTypes.object
}

ProductOptionSelector.defaultProps = {
  alignment: 'left',
  reversed: false,
  size: '3.6rem',
  labelSx: {}
}

export default ProductOptionSelector
